@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400&display=swap');
<link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700&display=swap" rel="stylesheet">

.home-container {
    background-color: #f2f2f2; /* You can adjust the shade of gray by modifying the hexadecimal color code */
    /* Add any other styles for the home section */
    font-family: "Times New Roman", sans-serif;
    font-weight: 600;
    font-size: 50px; /* Adjust size for the header */
  color: white; /* Dark color to make it stand out */
  }

  .home-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

  }
  
  .home-content {
    display: flex;
    align-items: center;
  }
  
  .home-image {
    max-width: 500px; /* Adjust the size of the image */
    margin-right: 90px; /* Space between the image and the text */
    margin-left: 40px;
  }
  
  .home-text {
    max-width: 500px; /* Optional: Limit the width of the text */
  }
  

.section-title {
  margin: 0;
  padding-bottom: 32px;
}
body {
  background: #ffffff;
  font-family: Lora, serif;
  font-style: normal;
  font-weight: normal;
  overflow-x: hidden;
}

/* Apply Oswald font to the h2 header */
.section-title h2 {
  font-family: 'Oswald', sans-serif; /* Modern, bold font */
  font-size: 36px; /* Adjust size for the header */
  color: #333; /* Dark color to make it stand out */
  margin-top: 50px; /* Adds space above the header */
  letter-spacing: 1px; /* Adds spacing between letters for a clean look */
  text-transform: uppercase; /* Makes the text all caps for emphasis */
  font-weight: 500; /* Medium weight for better readability */
}

.home-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

}
