@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400&display=swap');

#about-me {
    background-color: white; /* Set background to white */
    color: #333;           /* Ensure text color is visible */
    padding: 50px 20px;      /* Optional: Add padding for layout */
    font-family: "Times New Roman", Times, serif;
  }
  

  
  .container {
    display: flex;
    justify-content: space-between; /* Creates space between the columns */
    align-items: flex-start; /* Align items to the top */
    gap: 20px; /* Space between columns */
  }
  
  .info-list {
    list-style-type: none;
    padding-left: 0;
  }
  
  .info-list li {
    margin-bottom: 10px;
  }
  
  .social-icon {
    display: flex;
    list-style-type: none;
    padding-left: 0;
  }
  
  .social-icon li {
    margin-right: 15px;
  }
  
  .social-icon a {
    color: #333;
    font-size: 24px;
  }
  
  .about-text p {
    margin-bottom: 20px;
  }
  
  .btn-custom {
    padding: 10px 20px;
    text-decoration: none;
    color: #fff;
    background-color: #007bff;
    border-radius: 5px;
  }
  
  /* Flex layout for the two columns */
  .left-column {
    flex: 1; /* 1/3rd of the space */
    max-width: 30%; /* Max width to simulate the 4-column layout */
  }
  
  .right-column {
    flex: 2; /* 2/3rds of the space */
    max-width: 70%; /* Max width to simulate the 8-column layout */
  }
  

/* Style the info list items with a line under each */
.info-list li {
  font-family: "Times New Roman", Times, serif;
  color: #333;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
}

/* Apply Oswald font to the labels (e.g., Name:, Pronouns:, etc.) */
.info-list li strong {
  font-family: 'Oswald', sans-serif;
  color: #333;
  font-weight: bold;
  letter-spacing: 2px; 
}

/* Optional: Remove the line from the last item */
.info-list li:last-child {
  border-bottom: none;
}