.timeline-block .section-header{
	margin-bottom:80px;
}
.timeline{
	margin:0;
	padding:0;
	list-style:none;
	position:relative;
	padding:40px 0;
}
.timeline:before{
	content: ' ';
	position:absolute;
	top:0;
	left:0;
	height:100%;
	width:1px;
	background:#D0D0D0;
}
.timeline > li{
	padding-left:40px;
	margin-bottom:40px;
	font-size:14px;
	color:rgba(0,0,0,.6);
	line-height:1.8em;
}
.timeline > li:last-of-type{
	margin-bottom:0;
}
.timeline > li > .timeline-content{
	padding:25px;
	border:solid 1px #B6B6B6;
	position:relative;
}
.timeline > li > .timeline-content:before{
	content: ' ';
	position:absolute;
	top:15px;
	left:-40px;
	width:40px;
	height:1px;
	background:#B6B6B6;
}
.timeline > li > .timeline-content:after{
	content: ' ';
	position:absolute;
	height:16px;
	width:16px;
	border-radius:50%;
	background:#F0F0F0;
	border:solid 1px #B6B6B6;
	top:7px;
	left:-48px;
}
.timeline > li > .timeline-content > h4{
	margin:0;
	margin-bottom:5px;
}
.timeline > li > .timeline-content > em{
	margin-bottom:10px;
	display:block;
	color:rgba(0,0,0,.8);
}
.timeline > li > .timeline-content > em > span{
	margin-right:10px;
}
.timeline > li > .timeline-content > p{
	margin:0;
	line-height:1.6em;
	color:rgba(0,0,0,.6);
}